import { type ReactNode } from 'react'
import { ButtonForm } from '../ButtonForm'
import { Loading } from '../Loading/Loading'

interface FormButton {
  children: ReactNode
  className?: string
  onClick?: () => void
  icon?: boolean
  disabled?: boolean
  isError?: boolean | string
  type?: 'button' | 'submit' | 'reset' | undefined
  typeButtonPrimary?: 'button' | 'submit' | 'reset' | undefined
  classButtonConfirm?: string
  primary?: boolean
  secondary?: boolean
  isProcessing?: boolean
}

function FormButton({
  children,
  onClick = () => true,
  className,
  disabled = false,
  type = 'submit',
  primary,
  secondary,
  isProcessing,
}: FormButton) {
  return (
    <ButtonForm
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      primary={primary}
      secondary={secondary}
    >
      {isProcessing && <Loading />}
      {children}
    </ButtonForm>
  )
}

FormButton.displayName = 'FormButton'
export { FormButton }
export default FormButton
