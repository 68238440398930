export interface FormText {
  className?: string
  children?: React.ReactNode | string
  htmlFor?: string
  text?: string
}

export function FormText({
  className,
  children,
  text,
  htmlFor = '',
}: FormText) {
  return (
    <label htmlFor={htmlFor} className={` ${className}`}>
      {children}
      {text}
    </label>
  )
}

export default FormText
