import clsx from 'clsx'
import Image from 'next/image'
import { type ReactNode } from 'react'

interface ButtonProps {
  children: ReactNode
  primary?: boolean
  secondary?: boolean
  className?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'submit' | 'button' | 'reset' | undefined
  iconUrl?: string | null | undefined
}

export const ButtonForm = ({
  children,
  primary = false,
  secondary = false,
  disabled = false,
  className = '',
  onClick,
  type = 'button',
  iconUrl,
}: ButtonProps) => {
  const primaryClassNames = primary
    ? `label-button bg-primary-dark hover:bg-dark-dark text-primary-white py-3 px-4 transition duration-500 disabled:bg-dark-medium disabled:cursor-not-allowed`
    : ''

  const secondaryClassNames = secondary
    ? `label-button border border-primary-dark text-dark-400 py-3 px-4 lg:hover:bg-dark-light lg:hover:border-dark-light transition duration-300 ${
        disabled && '!bg-dark-dark cursor-not-allowed'
      }`
    : ''

  const buttonClassNames = clsx(
    primaryClassNames,
    secondaryClassNames,
    className
  )

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={buttonClassNames}
      type={type}
    >
      {iconUrl && (
        <span className='inline-block	h-3.5 pr-2'>
          <Image alt='button icon' src={iconUrl || ''} width={18} height={18} />
        </span>
      )}
      {children}
    </button>
  )
}
