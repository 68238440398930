import { useMemo } from 'react'
export interface FormView {
  className?: string
  buttonClassName?: string
  children: JSX.Element[] | JSX.Element
  onSubmit?: () => void
}

export function FormView({
  className = '',
  children,
  onSubmit = () => true,
  buttonClassName = '',
}: FormView) {
  const mainChild = useMemo(
    () =>
      'filter' in children
        ? children.filter(f => f.type?.displayName !== 'FormButton')
        : children.type?.displayName !== 'FormButton' && children,
    [children]
  )
  const handleChild = useMemo(
    () =>
      'filter' in children
        ? children.filter(f => f.type?.displayName === 'FormButton')
        : children.type?.displayName === 'FormButton' && children,
    [children]
  )
  return (
    <form onSubmit={onSubmit} className={`flex w-full flex-col ${className}`}>
      {mainChild}
      <div className={`${buttonClassName}`}>{handleChild}</div>
    </form>
  )
}

export default FormView
