import { HeaderLogo } from '@/components/HeaderLogo'

const HeaderNoMenu = () => {
  return (
    <>
      <header className='flex items-center justify-center py-[15px]'>
        <div className='max-w-[240px] flex-[240px] justify-center'>
          <HeaderLogo />
        </div>
        <hr
          className={`before:solid static h-0 border-none pt-[10px] before:absolute before:right-0 before:top-[100px] before:block before:w-full before:max-w-[100%] before:border before:border-dark-100 before:content-['']`}
        />
      </header>
    </>
  )
}

export default HeaderNoMenu
