import { useStore } from '@/containers'
import type { BannerItem as BlockBanner } from '@/types'
import clsx from 'clsx'
import Link from 'next/link'
import { useEffect, useState, type FC } from 'react'
import ImageOptimize from '../ImageOptimize'

interface Props {
  sideBarDataBanner?: Array<BlockBanner>
  className?: string
}

export const SideBarBanner: FC<Props> = ({ className = '' }: Props) => {
  const { sideBarDataBanner } = useStore()
  const [showBanner, setShowBanner] = useState(true)
  const [sideOffsetTop, setSideOffsetTop] = useState(0)
  const handleScroll = () => {
    const d = document.getElementById('sideBanner')
    setSideOffsetTop(d?.offsetTop as number)
    setShowBanner(true)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [sideOffsetTop, showBanner])

  return (
    <>
      {sideBarDataBanner && sideBarDataBanner.length >= 1 ? (
        <Link
          id='sideBanner'
          href={sideBarDataBanner[0]?.link || '/'}
          className={clsx(
            sideOffsetTop >= 550
              ? 'top-[42%] translate-y-[-50%]'
              : 'top-[250px]',
            'sticky left-[calc(50%-878px)] float-left mb-2 hidden w-max text-[0px] fhd:block',
            className
          )}
        >
          <ImageOptimize
            width={200}
            height={340}
            src={sideBarDataBanner[0].image}
            alt={sideBarDataBanner[0].title}
          />
        </Link>
      ) : (
        ''
      )}
      {sideBarDataBanner && sideBarDataBanner.length > 1 ? (
        <Link
          href={sideBarDataBanner[1]?.link || '/'}
          className={clsx(
            sideOffsetTop >= 550
              ? 'top-[42%] translate-y-[-50%]'
              : 'top-[250px]',
            'sticky right-[calc(50%-878px)] float-right mb-2 hidden w-max text-[0px] fhd:block',
            className
          )}
        >
          <ImageOptimize
            width={200}
            height={340}
            src={sideBarDataBanner[1].image}
            alt={sideBarDataBanner[1].title}
            priority={true}
          />
        </Link>
      ) : (
        ''
      )}
    </>
  )
}
