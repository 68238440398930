import type { BannerItem } from '@/types'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'
interface Props {
  sliders?: Array<BannerItem>
}
export const LoginSlider: FC<Props> = ({ sliders }: Props) => {
  const sliderSettings: Settings = {
    autoplay: true,
    dots: false,
    autoplaySpeed: 5000,
    speed: 800,
    arrows: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  }
  const slider = useRef<Slider>(null)

  return (
    <div className={``}>
      <div>
        <Slider ref={slider} {...sliderSettings} className='max-w-screen flex'>
          {sliders &&
            sliders.map((slider: BannerItem, index: number) => (
              <Link href={slider.link || '/'} key={index}>
                <ImageOptimize
                  className='w-full object-cover'
                  src={slider.image}
                  alt={slider.title}
                  width={1366}
                  quality={100}
                  height={717}
                />
              </Link>
            ))}
        </Slider>
      </div>
    </div>
  )
}
