/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FormikErrors } from 'formik'
import {
  useMemo,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
} from 'react'
export interface FormInput {
  placeholder?: string
  className?: string
  isError?:
    | string
    | false
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined
  id?: string
  name?: string
  children?: JSX.Element[] | JSX.Element
  alert?: boolean
  value?: string | null
  icon?: JSX.Element
  type?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function FormInput({
  isError = false,
  className = '',
  placeholder = '',
  alert = false,
  children,
  icon,
  name = '',
  id = '',
  value,
  type = 'text',
  onChange = () => true,
  ...inputFields
}: FormInput &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const helperChild = useMemo(
    () =>
      children &&
      ('filter' in children
        ? children.filter(f => f.type?.displayName === 'FormTextHelper')
        : (children.type?.displayName === 'FormTextHelper' && [children]) ||
          []),
    [children]
  )

  const errorChild = useMemo(
    () =>
      children &&
      ('filter' in children
        ? children.filter(f => f.type?.displayName === 'FormTextRequire')
        : (children.type?.displayName === 'FormTextRequire' && [children]) ||
          []),
    [children]
  )
  // Default width is 100% , height is 40px
  return (
    <>
      <input
        placeholder={placeholder}
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        {...inputFields}
        className={`!h-12 w-full ${className} rounded-lg border p-3 shadow-none focus:border-primary-base focus:shadow-none focus:outline-none focus:ring-0 ${
          isError ? 'border-primary-second focus:ring-primary-second' : ''
        }`}
      />
      {icon && icon}
      {alert && (
        <div className={`${isError && errorChild && 'pt-2'}`}>
          {isError && errorChild}
          {helperChild}
        </div>
      )}
    </>
  )
}

export default FormInput
